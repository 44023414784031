import { CratLogo } from 'shared/assets';

import { crowdsaleAbi, erc20Abi } from '../abi';
import { Chains, ContractsNames, IContracts } from '../types';

export const contracts: IContracts['contracts'] = {
  [ContractsNames.Crat]: {
    testnet: {
      address: {
        [Chains.BSC]: '0x9e86f8C6556Ac7fbC7e920DFdEAa60ACB9fD4009',
      },
      abi: erc20Abi,
      symbol: 'CratD2C-Pre',
      decimals: 0,
      img: CratLogo,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0xc6D7062eD5B8Bf1532CBC101E4daa0cC0B06F2a2',
      },
      abi: erc20Abi,
      symbol: 'CratD2C-Pre',
      decimals: 0,
      img: CratLogo,
    },
  },

  [ContractsNames.Crowdsale]: {
    testnet: {
      address: {
        [Chains.BSC]: '0xaf8c2176d3847fE0f41AE49772746efA57ECd27d',
      },
      abi: crowdsaleAbi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x136d9994eDfB34072C11Ab669F49929bfA079b8e',
      },
      abi: crowdsaleAbi,
    },
  },

  [ContractsNames.USDT]: {
    testnet: {
      address: {
        [Chains.BSC]: '0x66fd91C06C4adB7c411324BC54d4AF89a6fE585a',
      },
      abi: erc20Abi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x55d398326f99059fF775485246999027B3197955',
      },
      abi: erc20Abi,
    },
  },
  // BUSD
  [ContractsNames.USDC]: {
    testnet: {
      address: {
        [Chains.BSC]: '0xE1B2A624147Ceb1154D132DFb3885a621DA165B7',
      },
      abi: erc20Abi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      },
      abi: erc20Abi,
    },
  },
};

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts,
};
