import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link as MuiLink, Stack, Typography } from '@mui/material';
import { ConnectWallet } from 'features';
import { COLOR_ACCENT, COLOR_BG, COLOR_BG_ALPHA_31, CratLogoAnimated, TRANSITION_300 } from 'shared';
import { navLinks } from 'widgets/layout/constants';

import { MobileMenu } from './ui';

export const Header = () => {
  return (
    <Stack
      component="header"
      sx={{
        height: 72,
        mb: { xs: 3, sm: 5.25 },
        background: { xs: COLOR_BG, md: COLOR_BG_ALPHA_31 },
      }}
    >
      <Container
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: '100%',
          }}
        >
          <RouterLink to="/">
            <Stack direction="row" alignItems="center" spacing={1.125}>
              <Box component="img" src={CratLogoAnimated} width={35} height={39} />
              <Typography variant="h2" fontSize={22}>
                CratD2C
              </Typography>
            </Stack>
          </RouterLink>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 2, md: 4.5, lg: 7.5 }}
          >
            <Stack
              component="nav"
              direction="row"
              alignItems="center"
              spacing={{ xs: 3.5, lg: 4.5 }}
              sx={{
                display: {
                  md: 'flex',
                  xs: 'none',
                },
              }}
            >
              {navLinks.map((link) =>
                link.isExternal ? (
                  <Typography
                    key={link.id}
                    component={MuiLink}
                    href={link.path}
                    target="_blank"
                    rel="noreferrer"
                    variant="subtitle1"
                    sx={{
                      transition: TRANSITION_300,
                      textDecoration: 'none',

                      '&:hover, &.active': {
                        color: COLOR_ACCENT,
                      },
                    }}
                  >
                    {link.title}
                  </Typography>
                ) : (
                  <Typography
                    component={RouterLink}
                    to={link.path}
                    key={link.id}
                    variant="subtitle1"
                    sx={{
                      transition: TRANSITION_300,
                      textDecoration: 'none',

                      '&:hover, &.active': {
                        color: COLOR_ACCENT,
                      },
                    }}
                  >
                    {link.title}
                  </Typography>
                ),
              )}
            </Stack>
            <ConnectWallet />
            <MobileMenu />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
