import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface TrustLedgerModalPayload {
  data?: null;
  type: ModalType.TrustLedger;
  isOpen?: boolean;
}

export const TrustLedgerModal = React.forwardRef<
  HTMLElement,
  Pick<TrustLedgerModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Distributed Trust Ledger (DTL)"
      icon={<Icons.Todo />}
    >
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        A Blockchain Tech Purely Decentralized with a Peer2Peer D2C Economy. Manufacturers of goods and services can
        easily connect directly with targeted consumers through a &quotDistributed Trust Ledger (DTL)&quot, where a
        20-year in history transaction can be traced in seconds with a transaction hash.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C Solidity eliminates multiple Taxations & Profit add-on goods and services. For example, the eCommerce
        distribution chain consists of numerous retailers, sometimes up to 5 different chains, before goods get to the
        end-user, ultimately increasing the prices to the ordinary man. In such nodes, CRAT can effectively expunge such
        discrepancies.
      </Typography>
    </ModalSidebar>
  );
});
