import { INoNameContract } from '@amfi/connect-wallet/src/interface';
import { CrowdsaleAbi, Erc20Abi, erc20Abi } from 'shared';
import Web3 from 'web3';

import { contractsConfig } from './contracts';
import { Chains, ChainType, ContractsNames } from './types';

export class ContractsService {
  private provider: Web3;

  constructor(provider: Web3) {
    this.provider = provider;
  }

  public getCrowdsaleContract(chainType: ChainType) {
    const { abi, address } = ContractsService.getContractData(ContractsNames.Crowdsale, Chains.BSC, chainType);
    return new this.provider.eth.Contract(abi, address) as unknown as CrowdsaleAbi;
  }

  public getErc20Contract(tokenAddress: string) {
    return new this.provider.eth.Contract(erc20Abi, tokenAddress) as unknown as Erc20Abi;
  }

  public static getContractData(contractName: ContractsNames, network: Chains, chainType: ChainType): INoNameContract {
    const { abi, address } = contractsConfig.contracts[contractName][chainType];

    return {
      address: address[network],
      abi,
    };
  }
}
