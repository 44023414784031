import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { MetamaskIcon, WalletConnectIcon } from 'features/connect-wallet/assets';
import { Chains, COLOR_GRAY_LIGHT_4, WalletProviders } from 'shared';
import { useWalletConnectorContext } from 'shared/config/wallet-connect/hooks/useWeb3Provider';

interface ConnectMenuProps {
  handleClose: VoidFunction;
}

export const ConnectMenu = ({ handleClose }: ConnectMenuProps) => {
  const { connect } = useWalletConnectorContext();

  const connectWallet = (provider: WalletProviders, chain: Chains) => {
    connect(provider, chain);
    handleClose();
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight={600} className="black center no-resize">
        Connect wallet
      </Typography>
      <Stack gap={2} mt={2}>
        <MenuItem
          onClick={() => connectWallet(WalletProviders.metamask, Chains.BSC)}
          sx={{
            padding: { xs: '16px', sm: '19px 23px' },
            backgroundColor: COLOR_GRAY_LIGHT_4,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <MetamaskIcon />
            <Typography variant="h4" className="black font-third">
              Metamask
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => connectWallet(WalletProviders.walletConnect, Chains.BSC)}
          sx={{
            padding: { xs: '16px', sm: '19px 23px' },
            backgroundColor: COLOR_GRAY_LIGHT_4,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <Box component="img" src={WalletConnectIcon} />
            <Typography variant="h4" className="black font-third">
              Wallet Connect
            </Typography>
          </Stack>
        </MenuItem>
      </Stack>
    </Box>
  );
};
