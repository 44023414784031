import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { routes, useSmoothTopScroll } from 'shared';

import { Home } from './home';

export const Routing = () => {
  const location = useLocation();

  useSmoothTopScroll(location.pathname);

  return (
    <Routes>
      <Route path="*" element={<Navigate to={routes.home.path} />} />
      <Route element={<Home />} path={routes.home.path}>
        <Route path=":address" />
      </Route>
    </Routes>
  );
};
