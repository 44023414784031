import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/store';

import { UserState } from './reducer';

const getUser = (state: State) => state.user;

const getProp =
  <T extends keyof UserState>(propKey: T) =>
  (state: State) =>
    state.user[propKey];

const selectUserWeb3Info = createSelector(getUser, ({ network, chainType, provider, address }) => ({
  network,
  chainType,
  provider,
  address,
}));

const selectIsAuthenticated = createSelector(getUser, ({ address }) => ({
  isAuthenticated: !!address,
  userAddress: address,
}));

const selectUserInfo = createSelector([getUser, selectIsAuthenticated], ({ isAdmin }, authenticatedData) => ({
  isAdmin,
  ...authenticatedData,
}));

const selectUserCrowdsaleInfo = createSelector(
  getUser,
  ({ tier, totalSpend, referralReceived, cratBalance, bonusTokensReceived }) => ({
    tier,
    totalSpend,
    referralReceived,
    cratBalance,
    bonusTokensReceived,
  }),
);

const selectUserStableBalances = createSelector(getUser, ({ usdcBalance, usdtBalance }) => ({
  USDT: usdtBalance,
  USDC: usdcBalance,
}));

export const selectors = {
  getUser,
  getProp,
  selectUserWeb3Info,
  selectIsAuthenticated,
  selectUserInfo,
  selectUserCrowdsaleInfo,
  selectUserStableBalances,
};
