import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CtvgModalPayload {
  data?: null;
  type: ModalType.CTVG;
  isOpen?: boolean;
}

export const CtvgModal = React.forwardRef<
  HTMLElement,
  Pick<CtvgModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Community Trust Vesting Grant (CTVG)"
      icon={<Icons.PersonSettings />}
      titleSx={{
        maxWidth: 338,
      }}
    >
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CTVG mechanism helps startup companies to achieve their desired goals. The community can rate startup companies,
        allowing such companies to receive a smart contract vesting grant. Such grants are obtained in a vesting
        progressive proportion. When a grant beneficiary effectively utilizes the allocated first-round amount, the
        vesting contract allows the specific ratio to be released for the next round of CTVG Grant disbursement.
      </Typography>
    </ModalSidebar>
  );
});
