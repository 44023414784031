import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MetamaskIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 34"
    sx={{
      width: '36px',
      height: '34px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path d="M34.2125 0.361816L19.457 11.6346L22.2009 4.99121L34.2125 0.361816Z" fill="#E17726" />
    <path
      d="M1.45239 0.375L13.4316 4.99187L16.0371 11.7225L1.45239 0.375ZM28.2733 24.3358L34.7947 24.4641L32.5155 32.4585L24.5576 30.1963L28.2733 24.3358ZM7.35565 24.3358L11.0575 30.1963L3.11273 32.4588L0.847656 24.4641L7.35565 24.3358Z"
      fill="#E27625"
    />
    <path
      d="M15.6853 10.0081L15.9519 18.896L7.97656 18.5212L10.245 14.9876L10.274 14.9535L15.6853 10.0081ZM19.8659 9.90869L25.3597 14.9539L25.3883 14.9879L27.6568 18.5212L19.6834 18.896L19.8659 9.90869ZM11.2932 24.3615L15.6479 27.8648L10.5895 30.3863L11.2932 24.3615ZM24.3412 24.3611L25.0304 30.3863L19.9861 27.8644L24.3412 24.3611Z"
      fill="#E27625"
    />
    <path
      d="M20.0963 27.5347L25.215 30.0938L20.4536 32.4304L20.5031 30.8861L20.0963 27.5347ZM15.5335 27.5357L15.1429 30.8607L15.1749 32.4286L10.4023 30.0938L15.5335 27.5357Z"
      fill="#D5BFB2"
    />
    <path
      d="M14.0561 20.0562L15.3939 22.9587L10.8398 21.5813L14.0561 20.0562ZM21.5763 20.0565L24.808 21.5813L20.2392 22.9584L21.5763 20.0565Z"
      fill="#233447"
    />
    <path
      d="M11.6387 24.3319L10.9026 30.5786L6.95703 24.4685L11.6387 24.3319ZM23.99 24.3319L28.672 24.4685L24.7116 30.5789L23.99 24.3319ZM27.7696 18.168L24.3621 21.7533L21.7354 20.514L20.4774 23.2438L19.6531 18.5493L27.7696 18.168ZM7.8574 18.168L15.9752 18.5493L15.1506 23.2438L13.8926 20.5143L11.2794 21.7533L7.8574 18.168Z"
      fill="#CC6228"
    />
    <path
      d="M7.62891 17.4326L11.4836 21.4713L11.6173 25.4586L7.62891 17.4326ZM28.0058 17.4253L24.0103 25.4655L24.1605 21.4713L28.0058 17.4253ZM15.7878 17.6784L15.943 18.6869L16.3265 21.1988L16.0801 28.9132L14.9147 22.7159L14.9144 22.652L15.7878 17.6784ZM19.8408 17.6645L20.7166 22.652L20.7163 22.7159L19.5482 28.9289L19.5017 27.3749L19.3196 21.1529L19.8408 17.6645Z"
      fill="#E27525"
    />
    <path
      d="M24.5024 21.3105L24.3721 24.7749L20.3053 28.0464L19.4834 27.4467L20.4046 22.5458L24.5024 21.3105ZM11.1406 21.3109L15.2243 22.5461L16.1455 27.4467L15.3236 28.0464L11.2568 24.7746L11.1406 21.3109Z"
      fill="#F5841F"
    />
    <path
      d="M9.625 29.3286L14.8276 31.8738L14.8057 30.7867L15.241 30.3924H20.3904L20.8413 30.7856L20.8079 31.8717L25.9778 29.3349L23.4622 31.4813L20.4204 33.6385H15.1993L12.1594 31.4723L9.625 29.3286Z"
      fill="#C0AC9D"
    />
    <path
      d="M19.7228 27.1958L20.4585 27.7322L20.8895 31.2832L20.2656 30.7395H15.3661L14.7539 31.2943L15.1711 27.7326L15.9068 27.1958H19.7228Z"
      fill="#161616"
    />
    <path
      d="M33.2375 0.673828L35.0086 6.15984L33.9025 11.707L34.6901 12.3346L33.6244 13.1742L34.4254 13.8128L33.3648 14.8102L34.016 15.2973L32.2876 17.3811L25.1991 15.2504L25.1378 15.2163L20.0299 10.7673L33.2375 0.673828ZM2.3922 0.673828L15.6001 10.7673L10.4918 15.2163L10.4306 15.2504L3.34207 17.3811L1.61406 15.2973L2.26459 14.8106L1.20428 13.8128L2.00397 13.1749L0.922115 12.3328L1.73965 11.705L0.621094 6.16019L2.3922 0.673828Z"
      fill="#763E1A"
    />
    <path
      d="M24.8522 14.7844L32.3629 17.0425L34.8031 24.807H28.3658L23.9299 24.8647L27.1557 18.3729L24.8522 14.7844ZM10.7773 14.7844L8.47313 18.3729L11.6995 24.8647L7.26601 24.807H0.839844L3.26653 17.0425L10.7773 14.7844ZM22.5679 4.9541L20.4668 10.8125L20.021 18.7262L19.8507 21.2067L19.8372 27.5434H15.7923L15.7791 21.2185L15.6081 18.7241L15.1619 10.8125L13.0615 4.9541H22.5679Z"
      fill="#F5841F"
    />
  </SvgIcon>
);
