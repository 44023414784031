import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CryptoChange: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 28 30"
    sx={{
      width: '28px',
      height: '30px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M8.19304 3.80664L6.01562 5.98406"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.01562 1.62915L8.19304 3.80657"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.19361 3.80664H5.29039C2.88506 3.80664 0.935547 5.75616 0.935547 8.16148"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8066 27.0324L21.9841 24.855"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9841 29.2099L19.8066 27.0325"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8066 27.0323H22.7099C25.1152 27.0323 27.0647 25.0828 27.0647 22.6775"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2745 6.14502C26.3795 9.25002 26.3795 14.3307 23.2745 17.4357C23.0263 17.6839 22.7548 17.8915 22.4805 18.0932"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2738 6.14516C20.1688 3.04016 15.0882 3.04016 11.9832 6.14516C11.7364 6.39193 11.5245 6.66048 11.3184 6.93193"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3545 19.0486C18.3545 23.4586 14.7806 27.0324 10.3706 27.0324C5.96059 27.0324 2.38672 23.4586 2.38672 19.0486C2.38672 14.6386 5.96059 11.0647 10.3706 11.0647C14.7806 11.0647 18.3545 14.6386 18.3545 19.0486"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8613 22.2971H8.05078V19.031H11.8598C12.7627 19.031 13.4929 19.7626 13.4929 20.6641V20.6641C13.4943 21.567 12.7642 22.2971 11.8613 22.2971Z"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.05078 19.0311V15.6619H11.449C12.3795 15.6619 13.1329 16.4153 13.1329 17.3472V17.3472C13.1329 18.2777 12.3795 19.0311 11.449 19.0311"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
