import { useCallback } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { COLOR_ACCENT, COLOR_WH, useAppDispatch, useIntervalAsync, useShallowSelector } from 'shared';
import { useWalletConnectorContext } from 'shared/config/wallet-connect/hooks/useWeb3Provider';

import { FontWeights } from '../../../../shared/config/theme/Typography';

import { BalanceCard, IcoCountdown } from './ui';

export const Price = () => {
  const dispatch = useAppDispatch();
  const { walletService } = useWalletConnectorContext();

  const { address: userAddress } = useShallowSelector(userModel.selectors.selectUserWeb3Info);

  const getUserInfo = useCallback(async () => {
    if (userAddress) {
      dispatch(userModel.thunks.getUserInfo({ web3Provider: walletService.Web3() }));
    }
  }, [userAddress, dispatch, walletService]);

  useIntervalAsync(getUserInfo, 20_000);

  return (
    <Box component="section" mt={{ xs: 7, md: 10 }} mb={{ xs: 4, md: 10 }} id="price">
      <Card
        component={Stack}
        alignItems="center"
        sx={{ width: '100%', py: { xs: '30px', sm: 7 }, borderColor: COLOR_ACCENT }}
      >
        <Typography
          textAlign="center"
          fontSize={32}
          lineHeight="42px"
          fontWeight={FontWeights.SemiBold}
          color={COLOR_WH}
        >
          🔥 ICO NOW LIVE @
        </Typography>
        <Typography
          mt={2}
          variant="h4"
          component="a"
          href="https://cratd2csmartchain.io"
          target="_blank"
          rel="noopener noreferrer"
          color={COLOR_ACCENT}
          sx={{ textDecoration: 'underline' }}
        >
          cratd2csmartchain.io
        </Typography>
        <IcoCountdown />
        <BalanceCard />
      </Card>
    </Box>
  );
};
