import { alpha } from '@mui/material';

import { COLOR_BG_BLACK, COLOR_GRAY, COLOR_RED, COLOR_SECONDARY, COLOR_STROKE, COLOR_WH } from '../colors.constants';

export const colorsTextField = {
  colorsTextFieldBackground: COLOR_BG_BLACK,
  colorsTextFieldBorder: COLOR_STROKE,
  colorsTextFieldColor: COLOR_WH,
  colorsTextFieldPlaceholderColor: alpha(COLOR_GRAY, 0.3),
  colorsTextFieldColorError: COLOR_RED,
  colorsTextFieldColorDisabled: COLOR_GRAY,
  colorsFormLabelTextField: COLOR_SECONDARY,
};
