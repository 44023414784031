import {
  COLOR_ACCENT,
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_RED,
  COLOR_SECONDARY,
  COLOR_STROKE,
  COLOR_TEXT_GRAY,
  COLOR_WH
} from '../colors';

// ===== Border-Radius
export const BORDER_RADIUS_XL = '24px';
export const BORDER_RADIUS_L = '16px';
export const BORDER_RADIUS_M = '12px';
export const BORDER_RADIUS_S = '8px';

// ===== Transitions
export const TRANSITION_300 = 'all 0.3s ease-out';
export const TRANSITION_500 = 'all 0.5s ease-out';

// ===== Borders
export const BORDER_BOLD = `2px solid`;
export const BORDER_MAIN = `1px solid ${COLOR_STROKE}`;
export const BORDER_MAIN_BOLD = `2px solid ${COLOR_STROKE}`;
export const BORDER_WHITE = `1px solid ${COLOR_WH}`;
export const BORDER_ACCENT = `2px solid ${COLOR_ACCENT}`;
export const getBorder = (size = '2px', color = COLOR_STROKE) => `${size} solid ${color}`;

export const GRADIENT_ACCENT = `linear-gradient(179.96deg, #B4EB56 0.03%, rgba(180, 235, 86, 0) 105.81%)`;

export const BOX_SHADOW_1 = `0px 64px 64px -48px #1F2F461F`;

export const colorVariations = {
  '&.secondary': {
    color: COLOR_SECONDARY,
  },
  '&.error': {
    color: COLOR_RED,
  },
  '&.black': {
    color: COLOR_BLACK,
  },
  '&.white': {
    color: COLOR_WH,
  },
  '&.accent': {
    color: COLOR_ACCENT,
  },
  '&.gray': {
    color: COLOR_GRAY,
  },
  '&.stroke': {
    color: COLOR_STROKE,
  },
  '&.text-gray': {
    color: COLOR_TEXT_GRAY
  }
};
