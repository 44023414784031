import { ComponentsOverrides, Theme } from '@mui/material';

import { BORDER_RADIUS_L } from '../common';

export const getMuiCardOverrides = (theme: Theme): ComponentsOverrides['MuiCard'] => ({
  root: {
    borderRadius: BORDER_RADIUS_L,
    border: '1px solid',
    borderColor: theme.themeColors.colorsCardBorder,
    backgroundColor: theme.themeColors.colorsCardBackground,
    padding: 16,
  },
});
