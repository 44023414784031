import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface FiatCryptoModalPayload {
  data?: null;
  type: ModalType.FiatCrypto;
  isOpen?: boolean;
}

export const FiatCryptoModal = React.forwardRef<
  HTMLElement,
  Pick<FiatCryptoModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="Fiat2Crypto" icon={<Icons.FiatChange />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        The ability to ease consumer transaction with merchants, a vital feature for blockchain-based platforms, as it
        provides an entry point for users into the world of digital assets for integration of Daily Business Usage, User
        Interface, Transaction Execution, and Crypto Wallet.
      </Typography>
    </ModalSidebar>
  );
});
