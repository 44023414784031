import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Play: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 54 54"
    sx={{
      width: '54px',
      height: '54px',
      ...sx,
    }}
    {...props}
  >
    <circle cx="27" cy="27" r="27" fill="#B4EB56" />
    <path
      d="M37.6504 26.134C38.3171 26.5189 38.3171 27.4811 37.6504 27.866L22.4254 36.6562C21.7587 37.0411 20.9254 36.5599 20.9254 35.7901L20.9254 18.2098C20.9254 17.44 21.7587 16.9589 22.4254 17.3438L37.6504 26.134Z"
      fill="black"
    />
  </SvgIcon>
);
