import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CryptoFiatModalPayload {
  data?: null;
  type: ModalType.CryptoFiat;
  isOpen?: boolean;
}

export const CryptoFiatModal = React.forwardRef<
  HTMLElement,
  Pick<CryptoFiatModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="Crypto2Fiat" icon={<Icons.CryptoChange />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        A mechanism that enables the ease of cryptocurrencies usage in the real world. This can be particularly
        beneficial for blockchain-based platforms as it provides a bridge between day-2-day traditional business world
        and the digital asset space for Ease of Use, Stability, and User Friendliness.
      </Typography>
    </ModalSidebar>
  );
});
