import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import throttle from 'lodash.throttle';
import { Icons, TRANSITION_500 } from 'shared';

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const throttledHandler = throttle(handleScroll, 150);

    window.addEventListener('scroll', throttledHandler);

    return () => {
      window.removeEventListener('scroll', throttledHandler);
    };
  }, []);

  return (
    <IconButton
      onClick={scrollToTop}
      className="rounded"
      sx={{
        position: 'fixed',
        zIndex: 1000,
        right: { xs: 10, md: 20 },
        bottom: { xs: 25, md: 100 },
        width: { xs: 68, md: 82 },
        height: { xs: 68, md: 82 },
        transition: TRANSITION_500,
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
    >
      <Icons.Arrow />
    </IconButton>
  );
};
