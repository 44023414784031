import {
  ApproveModalPayload,
  ApproveRejectModalPayload,
  CratNewModalPayload,
  CratTokenModalPayload,
  CratWidgetModalPayload,
  CryptoFiatModalPayload,
  CtvgModalPayload,
  DecentralizedCommunityModalPayload,
  DelegatorModalPayload,
  EcommerceTradingModalPayload,
  EstateTradingModalPayload,
  FiatCryptoModalPayload,
  LuxuryModalPayload,
  PaymentApiModalPayload,
  PaymentGatewayModalPayload,
  PosModalPayload,
  SendModalPayload,
  SendRejectModalPayload,
  SendSuccessModalPayload,
  TrustLedgerModalPayload,
  ValidatorModalPayload,
  VendingMachineModalPayload,
} from '../ui';

export enum ModalType {
  POS = 'POS',
  CratToken = 'CratToken',
  DecentralizedCommunity = 'DecentralizedCommunity',
  CTVG = 'CTVG',
  EcommerceTrading = 'EcommerceTrading',
  EstateTrading = 'EstateTrading',
  Luxury = 'Luxury',
  PaymentGateway = 'PaymentGateway',
  VendingMachine = 'VendingMachine',
  PaymentApi = 'PaymentApi',
  CratNew = 'CratNew',
  CratWidget = 'CratWidget',
  TrustLedger = 'TrustLedger',
  CryptoFiat = 'CryptoFiat',
  FiatCrypto = 'FiatCrypto',
  Delegator = 'Delegator',
  Validator = 'Validator',
  Approve = 'Approve',
  ApproveReject = 'ApproveReject',
  Send = 'Send',
  SendReject = 'SendReject',
  SendSuccess = 'SendSuccess',
}

export interface IModal<T extends { type: ModalType; data: any } = { type: ModalType; data: any }> {
  onClose: () => void;
  data: T['data'];
  isOpen?: boolean;
}
export type IModalPayload =
  | PosModalPayload
  | CratTokenModalPayload
  | DecentralizedCommunityModalPayload
  | CtvgModalPayload
  | EcommerceTradingModalPayload
  | EstateTradingModalPayload
  | LuxuryModalPayload
  | PaymentGatewayModalPayload
  | VendingMachineModalPayload
  | PaymentApiModalPayload
  | CratWidgetModalPayload
  | CratNewModalPayload
  | TrustLedgerModalPayload
  | CryptoFiatModalPayload
  | FiatCryptoModalPayload
  | DelegatorModalPayload
  | ValidatorModalPayload
  | ApproveModalPayload
  | ApproveRejectModalPayload
  | SendModalPayload
  | SendRejectModalPayload
  | SendSuccessModalPayload;

export type ModalTransition = 'fade' | 'slide';
