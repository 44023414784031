import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CratTokenModalPayload {
  data?: null;
  type: ModalType.CratToken;
  isOpen?: boolean;
}

export const CratTokenModal = React.forwardRef<
  HTMLElement,
  Pick<CratTokenModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="CRAT Token" icon={<Icons.CratToken />}>
      CRAT is a treasurable asset base decentralized ecosystem where manufacturers of products can easily connect with
      targeted consumers by discharging the bureaucracy of retailers in the realism of the Direct-2-Consumer economy. A
      utility reflects Token built on Binance Smart Chain, with maximum emission of 40 million tokens.
    </ModalSidebar>
  );
});
