export const list = [
  {
    title: '$1,000 PRE-SEED PACKAGE',
    items: [
      { id: '1', text: '3% BONUS in CratD2C-Pre tokens ' },
      { id: '2', text: '10% BONUS for the entire Campaign period of CratD2C IP-Share SEED ROUND.' },
      { id: '3', text: 'Delegator status' },
    ],
  },

  {
    title: '$2,000 PRE-SEED PACKAGE',
    items: [
      { id: '1', text: '5% BONUS in CratD2C-Pre tokens ' },
      { id: '2', text: '15% BONUS for the entire Campaign period of CratD2C IP-Share SEED ROUND.' },
      { id: '3', text: 'Delegator status' },
    ],
  },

  {
    title: '$5,000 PRE-SEED PACKAGE',
    items: [
      { id: '1', text: '10% BONUS in CratD2C-Pre tokens ' },
      { id: '2', text: '25% BONUS for the entire Campaign period of CratD2C IP-Share SEED ROUND.' },
      { id: '3', text: 'Delegator status' },
    ],
  },

  {
    title: '$10,000 PRE-SEED PACKAGE',
    items: [
      { id: '1', text: '15% BONUS in CratD2C-Pre tokens ' },
      { id: '2', text: '35% BONUS for the entire Campaign period of CratD2C IP-Share SEED ROUND.' },
      { id: '3', text: 'Delegator status' },
    ],
  },
];
