import { ComponentsOverrides, ComponentsProps, Fade, Theme } from '@mui/material';

import { BOX_SHADOW_1 } from '../common';

export const getMuiPopoverOverrides = (theme: Theme): ComponentsOverrides['MuiPopover'] => ({
  paper: {
    boxShadow: BOX_SHADOW_1,
    borderRadius: '20px',
    margin: '8px',
  },
  root: {
    '.MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({
  TransitionComponent: Fade,
  transitionDuration: 650,
});
