import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FiatChange: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 32 31"
    sx={{
      width: '32px',
      height: '31px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3691 15.626L15.7913 19.0481L12.3691 22.4702L8.947 19.0481L12.3691 15.626Z"
      stroke="#B4EB56"
      strokeWidth="1.49999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1921 3.80664H7.28887C4.08226 3.80664 1.48242 6.40648 1.48242 9.61309"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.28906 0.90332L10.1923 3.80655L7.28906 6.70977"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8047 27.0323H24.7079C27.9145 27.0323 30.5144 24.4324 30.5144 21.2258"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7079 29.9356L21.8047 27.0324L24.7079 24.1292"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6777 11.0995C12.029 7.01471 15.4505 3.80664 19.6268 3.80664C24.0368 3.80664 27.6106 7.38051 27.6106 11.7905C27.6106 15.9668 24.4026 19.3883 20.3177 19.7395"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0141 13.4031C21.132 16.521 21.132 21.5761 18.0141 24.694C14.8962 27.8119 9.84107 27.8119 6.72318 24.694C3.60529 21.5761 3.60529 16.521 6.72318 13.4031C9.84107 10.2852 14.8962 10.2852 18.0141 13.4031"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
