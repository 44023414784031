import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Popover } from '@mui/material';
import { userModel } from 'entities/user';
import {
  COLOR_GRAY_LIGHT,
  COLOR_PRIMARY,
  COLOR_STROKE,
  Icons,
  scrollToElement,
  shortenPhrase,
  useBreakpoints,
  useShallowSelector,
} from 'shared';

import { connectModalModel } from '..';

import { ConnectMenu } from './connect-menu';
import { LogoutMenu } from './logout-menu';

export const ConnectWallet = () => {
  const dispatch = useDispatch();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [anchorElLogout, setAnchorElLogout] = useState<HTMLButtonElement | null>(null);
  const { isAuthenticated, userAddress } = useShallowSelector(userModel.selectors.selectUserInfo);

  const { open: isConnectModalOpen, anchorId } = useShallowSelector(connectModalModel.selectors.connectModalSelector);

  const { isDownMdLayout } = useBreakpoints();

  const open = isConnectModalOpen && Boolean(anchorEl);
  const openLogout = Boolean(anchorElLogout);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isAuthenticated) {
      return dispatch(connectModalModel.actions.openModal());
    }

    return setAnchorElLogout(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorId) {
      scrollToElement(anchorId);
      setTimeout(() => {
        dispatch(connectModalModel.actions.closeModal());
      }, 500);
    } else {
      dispatch(connectModalModel.actions.closeModal());
    }
  };

  const handleCloseLogout = () => {
    setAnchorElLogout(null);
  };

  return (
    <>
      {isDownMdLayout ? (
        <IconButton
          id="connect-button"
          ref={anchorEl}
          onClick={handleClick}
          color="secondary"
          sx={{
            position: 'relative',
          }}
        >
          {!isAuthenticated ? (
            <Icons.CancelCircleRed
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translate(50%, -50%)',
              }}
            />
          ) : (
            <Icons.ConfirmCircleGreen
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translate(50%, -50%)',
              }}
            />
          )}
          <Icons.Wallet
            sx={{
              color: COLOR_STROKE,
            }}
          />
        </IconButton>
      ) : (
        <Button
          id="connect-button"
          ref={anchorEl}
          onClick={handleClick}
          variant="outlined"
          sx={{
            fontSize: 14,
            fontWeight: 400,
            px: 2,
            width: '145px',
            borderColor: COLOR_PRIMARY,
            color: COLOR_GRAY_LIGHT,
          }}
        >
          {isAuthenticated ? shortenPhrase(userAddress, 6) : 'Connect wallet'}
        </Button>
      )}
      <Popover
        open={open}
        closeAfterTransition
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            padding: { xs: '24px 16px', sm: '32px 20px' },
            maxWidth: { sm: 350 },
            width: '100%',
            mt: 1,
          },
        }}
      >
        <ConnectMenu handleClose={handleClose} />
      </Popover>
      <Popover
        open={openLogout}
        anchorEl={anchorElLogout}
        onClose={handleCloseLogout}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            padding: { xs: '28px', sm: '32px' },
            maxWidth: { sm: 350 },
            width: '100%',
            mt: 1,
          },
        }}
      >
        <LogoutMenu handleClose={handleCloseLogout} />
      </Popover>
    </>
  );
};
